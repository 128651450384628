import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { zodResolver } from "@hookform/resolvers/zod";
import { CatButton } from "blocks-react/bedrock/components/Button/Button";
import { CatDropdown } from "blocks-react/bedrock/components/Dropdown/Dropdown";
import { CatInputField } from "blocks-react/bedrock/components/InputField/InputField";
import { CatList } from "blocks-react/bedrock/components/List/List";
import { CatListItem } from "blocks-react/bedrock/components/ListItem/ListItem";
import { CatModal } from "blocks-react/bedrock/components/Modal/Modal";
import { CatTextPassage } from "blocks-react/bedrock/components/TextPassage/TextPassage";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
const AssetDetailsModal = (props) => {
    const { defaultValues, heading, isEditAssetNickName, onApplyChanges, onCloseModal, showModal, subTitle } = props;
    const ThirdPartyAssetDetailsSchema = z.object({
        assetId: z.string().trim().min(2, "Asset Id is required"),
        assetMetadata: z.object({}),
        assetName: z.string().trim().min(2, "Asset Name is required"),
        assetSerialNumber: z.string().trim().min(2, "Serial number is required"),
        assetSourceId: z.string().trim().optional(),
        assetSourceType: z.enum(["ESS", "Machine"])
    });
    const AssetIdSchema = z.object({
        assetId: z.string().optional()
    });
    const validationSchema = isEditAssetNickName ? AssetIdSchema : ThirdPartyAssetDetailsSchema;
    const { control, formState: { errors, isDirty, isValid }, getValues, reset } = useForm({
        defaultValues,
        mode: "onBlur",
        resolver: zodResolver(validationSchema)
    });
    return (_jsx("form", { children: _jsxs(CatModal, { disableBackdropClick: true, heading: heading, headingVariant: "title", isActive: showModal, onBlModalClose: () => {
                onCloseModal();
            }, size: "sm", children: [_jsxs("div", { className: "flex flex-col gap-5", children: [_jsx(CatTextPassage, { size: "sm", children: _jsx("p", { children: subTitle }) }), _jsx(Controller, { control: control, defaultValue: "", name: "assetId", render: ({ field: { onBlur, onChange, ref, value } }) => (_jsx(CatInputField, { ariaDescribedBy: "text-field-aria-1", fieldId: "text-field-1", label: "AssetId", onBlChange: onChange, onBlur: onBlur, placeholder: "Enter asset id", ref: ref, size: "lg", value: value, ...("assetId" in errors && {
                                    errorNote: errors.assetId.message,
                                    status: "error"
                                }) })), shouldUnregister: true }), _jsx(Controller, { control: control, defaultValue: "", name: "assetName", render: ({ field: { onBlur, onChange, ref, value } }) => (_jsx(CatInputField, { ariaDescribedBy: "text-field-aria-1", disabled: isEditAssetNickName, fieldId: "text-field-11", label: "assetName", onBlChange: onChange, onBlur: onBlur, placeholder: "Enter Asset Name", ref: ref, required: true, size: "lg", value: value, ...("assetName" in errors &&
                                    errors.assetName && {
                                    errorNote: errors.assetName.message,
                                    status: "error"
                                }) })), shouldUnregister: true }), _jsx(Controller, { control: control, defaultValue: "", name: "assetSerialNumber", render: ({ field: { onBlur, onChange, ref, value } }) => (_jsx(CatInputField, { ariaDescribedBy: "text-field-aria-1", disabled: isEditAssetNickName, fieldId: "text-field-1", label: "Serial#", onBlChange: onChange, onBlur: onBlur, placeholder: "Enter Serial Number", ref: ref, required: true, size: "lg", value: value, ...("assetSerialNumber" in errors && {
                                    errorNote: errors.assetSerialNumber.message,
                                    status: "error"
                                }) })), shouldUnregister: true }), _jsx(Controller, { control: control, defaultValue: "", name: "assetSourceId", render: ({ field: { onBlur, onChange, ref, value } }) => (_jsx(CatInputField, { ariaDescribedBy: "text-field-aria-1", disabled: isEditAssetNickName, fieldId: "text-field-1", label: "assetSourceId", onBlChange: onChange, onBlur: onBlur, placeholder: "Enter SourceId", ref: ref, required: true, size: "lg", value: value, ...("assetSourceId" in errors && {
                                    errorNote: errors.assetSourceId.message,
                                    status: "error"
                                }) })), shouldUnregister: true }), _jsx(Controller, { control: control, name: "assetSourceType", render: ({ field: { onBlur, onChange, ref, value } }) => (_jsx(CatDropdown, { ariaDescribedBy: "dropdown-field-aria-1", disabled: isEditAssetNickName, fieldId: "dropdown-1", label: "Asset source type", onBlSelect: onChange, onBlur: onBlur, placeholder: "Select an option...", ref: ref, required: true, value: value, ...("assetSourceType" in errors &&
                                    errors?.assetSourceType?.message && {
                                    errorNote: errors.assetSourceType.message,
                                    status: "error"
                                }), children: _jsx(React.Fragment, { children: _jsxs(CatList, { spacing: "padded", children: [_jsx(CatListItem, { value: "Machine", children: "Machine" }), _jsx(CatListItem, { value: "ESS", children: "ESS" })] }) }, ".0") })), shouldUnregister: true })] }), _jsxs("div", { className: "flex gap-3", slot: "footer", children: [_jsx(CatButton, { disabled: !isDirty || !isValid, onClick: () => {
                                const fieldValues = {
                                    assetId: getValues("assetId"),
                                    assetMetadata: {},
                                    assetName: getValues("assetName"),
                                    assetSerialNumber: getValues("assetSerialNumber"),
                                    assetSourceId: getValues("assetSourceId"),
                                    assetSourceType: getValues("assetSourceType")
                                };
                                onApplyChanges(fieldValues);
                                reset();
                            }, variant: "primary", children: "Apply" }), _jsx(CatButton, { onClick: () => {
                                onCloseModal();
                            }, variant: "ghost", children: "Cancel" })] })] }) }));
};
export default AssetDetailsModal;
